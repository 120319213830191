.page-login {
	display: flex;
	min-height: 100vh;
	padding: 50px;
	background: var(--color-bg-light);

	.apresentation {
		background-image: linear-gradient(#4682b4, #4682b4);
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		padding: 100px;
		box-sizing: border-box;
		min-width: 200px;
		border-radius: 20px 0px 0px 20px;
		.description {
			h1 {
				font-size: 40px;
				color: var(--color-white);
				font-weight: 400;
				text-align: center;
			}
			p {
				text-align: center;
				color: var(--color-white);
				font-weight: 200;
				margin-top: 20px;
				line-height: 30px;
			}
			img {
				max-width: 100%;
			}
		}
	}
	.formLogin {
		min-width: 800px;
		background: var(--color-white);
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 100px;
		border-radius: 0px 20px 20px 0px;
		flex-wrap: wrap;
		.boxLogin {
			width: 51%;
			padding-bottom: 50px;
		}
		h2 {
			font-size: 30px;
			margin-bottom: 50px;
			color: var(--color-primary);
			font-weight: 400;
			text-align: center;
		}
	}

	.formSetup {
		min-width: 800px;
		background: var(--color-white);
		flex: 1;
		flex-wrap: wrap;
		display: flex;
		align-items: center;
		align-content: space-between;
		justify-content: center;
		padding: 50px;
		border-radius: 20px 20px 20px 20px;
		flex-wrap: wrap;
		img {
			max-width: 100px;
			max-height: 100px;
		}
		.boxLogin {
			width: 100%;
			padding-bottom: 50px;
		}
		.row {
			display: flex;
			align-items: center;
		}
		.department {
			text-align: center;
			height: 500px;
			margin-top: 20px;
			margin-bottom: 20px;
			overflow: auto;
		}
		h2 {
			font-size: 30px;
			margin-bottom: 50px;
			color: var(--color-primary);
			font-weight: 400;
			text-align: center;
		}
		h4 {
			text-align: center;
		}
		p {
			font-size: 20px;
			font-weight: 100;
			text-align: center;
		}
		.close {
			top: 80px;
			right: 80px;
			position: absolute;
		}
		.companyName {
			top: 80px;
			left: 80px;
			position: absolute;
		}
	}
}
