@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./components/theme/tables.scss";

html {
  --color-white: #ffffff;
  --color-primary: #4682B4;
  --color-primary-dark: #4682B4;
  --color-primary-degrade: #4682B4;
  --color-secundary: #4682B4;
  --color-light: #4682B4;
  --color-bg-light: #ecf0f1;
  --color-dark: #383838;
  --color-white: #ffffff;

  --color-error: #ad0909;
  --color-error-bg: #ffe6e6;

  --color-success: #118220;
  --color-success-bg: #e0f5de;

  --color-info: #5876af;
  --color-info-bg: #e9f9ff;

  --color-alert: #a2910d;
  --color-alert-bg: #fdfad3;
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

body {
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100%;
}

html,
body,
div,
span,
applet,
object,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

*:focus {
  outline: none;
}

.container {
  margin: 0 auto;
  padding: 20px;
}

form {
  .formGroup {
    label {
      margin-top: 10px;
      margin-bottom: 5px;
      display: block;
      color: var(--color-dark);
      font-size: 17px;
    }
    input,
    select,
    textarea {
      width: 100%;
      margin-bottom: 20px;
      padding: 10px 20px;
      border: solid 1px var(--color-bg-light);
      background: var(--color-bg-light);
      color: var(--color-dark);
      font-size: 15px;
      border-radius: 5px;
      border: solid 1px #d8d8d8;
    }
  }
  .actions {
    display: flex;
    margin-top: 20px;
    justify-content: space-around;
  }

  &.formGroup-Block-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .formGroup {
      width: 48%;
    }
  }
}

.actions {
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap;
  &.actions-right {
    display: flex;
    justify-content: flex-end;
  }
}

button {
  background: var(--color-primary);
  border-radius: 8px;
  font-weight: 300;
  color: var(--color-white);
  border: none;
  padding: 13px 25px;
  font-size: 18px;
  min-width: none;
  min-width: 160px;
  cursor: pointer;
  &.btn-invert {
    background: var(--color-white);
    color: var(--color-primary);
    border: solid 1px var(--color-primary);
  }
  svg {
    height: 23px;
    width: 23px;
  }
  &:disabled{
    opacity: 0.4;
  }
  &.btn-danger {
    background: var(--color-error) !important;
    border: solid 1px var(--color-error) !important;
    color: var(--color-white) !important;
  }
  &.btn-primary {
    color: var(--color-white) !important;
    background: var(--color-primary) !important;
    border: solid 1px var(--color-primary) !important;
  }
}

.maskLoading {
  circle {
    fill: var(--color-primary);
    &:nth-child(2) {
      fill: var(--color-primary-degrade);
    }
    &:nth-child(3) {
      fill: var(--color-secundary);
    }
    &:nth-child(4) {
      fill: var(--color-primary);
    }
    &:nth-child(5) {
      fill: var(--color-secundary);
    }
  }
}

.msg-error {
  width: 100%;
  background: red;
  padding: 8px;
  border-radius: 5px;
  font-size: 13px;
  color: var(--color-error);
  background: var(--color-error-bg);
}
.msg-success {
  width: 100%;
  background: red;
  padding: 8px;
  border-radius: 5px;
  font-size: 13px;
  color: var(--color-success);
  background: var(--color-success-bg);
}
.msg-alert {
  width: 100%;
  background: red;
  padding: 8px;
  border-radius: 5px;
  font-size: 13px;
  color: var(--color-alert);
  background: var(--color-alert-bg);
}
.msg-info {
  width: 100%;
  background: red;
  padding: 8px;
  border-radius: 5px;
  font-size: 13px;
  color: var(--color-info);
  background: var(--color-info-bg);
}

.card {
  padding-top: 20px;
  background: var(--color-white);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
}


.react-confirm-alert-overlay{

  .react-confirm-alert {
    text-align: center;
    width: 500px;
    padding: 40px;
    background: var(--color-primary);
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
    .react-confirm-alert-body{
      background: transparent;
      box-shadow: none;
      padding: 20px 0px;
      text-align: center;
      color: #fff;
      font-size: 19px;
      h1 {
        text-align: center;
        font-size: 25px;
        margin-bottom: 20px;
      }
      .react-confirm-alert-button-group{
        display: flex;
        justify-content: space-around;
        button{
          margin-top: 20px;
          background: transparent;
          border: solid 1px white;
          font-size: 17px;

        }
      }
    }
  }

  &.overlay-custom-danger{
    .react-confirm-alert{
      background: var(--color-white);
      border: solid 1px var(--color-error);
      .react-confirm-alert-body{
        color: var(--color-error);
        h1{
          color: var(--color-error);
        }
      }

    }
  }
}

.row {
  padding-bottom: 1em;
}

button {
  margin-right: 0.5em;
}

.legend {
  font-size: 0.8em !important;
  margin-bottom: 0.8em;
}

.clickable {
  cursor: pointer;
}

.error {
  color: var(--color-error);
}

.primary-color {
  color: var(--color-primary);
}

svg {
  cursor: pointer;
}

.header-crud{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}