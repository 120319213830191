table.table{
  width: 100%;
  thead {
    tr {
      // background: var(--color-primary-degrade);
      th {
        // text-align: center;
        // padding: 15px;
        // font-weight: 400;
        // color: var(--color-primary-dark);
        // border: solid 1px var(--color-primary);
      }
    }
  }
  tbody {
    tr {
      border-bottom: solid 1px #d1d1d1;
      td {
        background: #f5f5f5;
        padding: 10px;
        .actions{
          display: flex;
          width: 250px;
          justify-content: space-around;
          align-items: center;
          margin-bottom: 0px;
          button {
            width: 70px;
            min-width: 20px;
            font-size: 12px;
            padding: 3px 10px;
            &.edit {
              background: var(--color-alert-bg);
              color: var(--color-alert);
            }
            &.danger {
              background: var(--color-error-bg);
              color: var(--color-error);
            }
          }
        }
      }
      &:nth-child(2n){
        td{
          background: #fff;
        }
      }
    }
  }
}