
body.showMenu{
  background: #f5f5f5;
  padding-top: 120px !important;
  padding-left: 270px !important;
  padding-right: 20px;
}



.mainMenu {
  width: 250px;
  background: #fff;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  z-index: 1;
  padding-top: 100px;
  box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.1);
  ul {
    li {
      a {
        display: block;
        padding: 20px;
        border-bottom: solid 1px #efeeee;
        font-weight: 400;
        text-decoration: none;
        color: var(--color-dark);
        &.active {
          color: var(--color-white);
          background: var(--color-primary);
          &:hover {
            color: var(--color-white) !important;
          }
        }
        &:hover {
          color: var(--color-primary);
        }
      }
    }
  }
}
