.mainHeader {
    padding: 30px;
    background: var(--color-primary);
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    box-shadow: 6px 0px 10px rgba(0,0, 0, 0.5);
    width: 100%;
    left: 0px;
    top: 0px;
    h1{
        color: white;
        text-align: center;
        font-size: 20px;
    }
    span{
        color: white;
        display: block;
        text-align: center;
    }
    .user{
        display: flex;
        flex-direction: row;
        align-items: center;
        .logout-icon{
            margin-left: 15px;
            color: white;
            cursor: pointer;
            .icon{
                font-size: 20px;
            }
        }
    }
    .logo {
        display: flex;
        align-items: center;
        width: 300px;
        img {
            max-width: 50px;
            max-height: 50px;
            padding-right: 15px;
            //radius
            border-radius: 50%;
        }
    }
}