.page-report {
	.cardTotal {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	
		.number {
			margin-bottom: 20px;
			font-size: 32px;
		}

		.title {
			font-size: 20px;
			color: var(--color-primary);
		}
		.danger {
			color: var(--color-error);
		}
		.success {
			color: var(--color-success);
		}
		.alert {
			color: var(--color-alert);
		}
	}
}